/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';
import './inc/responsive-menus';
import onScroll from './inc/on-scroll';
import progressBar from './inc/update-progress';
import linkScroll from './inc/smooth-scroll';
// import './inc/in-view';

$(window).on('load', () => {
	onScroll.init();
	onScroll.backTop();
	progressBar.init();
	setTimeout(linkScroll.init, 320);
});
