const progressBar = ((window, $) => {
	const init = () => {
		const progressPath = document.querySelector('.progress-wrap path');
		const pathLength = progressPath.getTotalLength();
		progressPath.style.transition = progressPath.style.WebkitTransition =
			'none';
		progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
		progressPath.style.strokeDashoffset = pathLength;
		progressPath.getBoundingClientRect();
		progressPath.style.transition = progressPath.style.WebkitTransition =
			'stroke-dashoffset 10ms linear';
		$(window).on('scroll', () => {
			const scroll = $(window).scrollTop();
			const height = $(document).height() - $(window).height();
			progressPath.style.strokeDashoffset =
				pathLength - (scroll * pathLength) / height;
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default progressBar;
