const onScroll = ((window, $) => {
	const init = () => {
		const $window = $(window);
		$window.on('scroll', function() {
			const bodyScroll = $window.scrollTop(),
				navbar = $('.nav-primary');
			// logo = $('.navbar .logo> img');
			if (bodyScroll > 100) {
				navbar.addClass('nav-primary-with-scroll');
				// logo.attr('src', 'img/logo-dark.png');
			} else {
				navbar.removeClass('nav-primary-with-scroll');
				// logo.attr('src', 'img/logo.png');
			}
		});
	};
	const backTop = () => {
		const progressWrap = $('.progress-wrap'),
			offset = 150,
			duration = 550;
		$(window).on('scroll', function() {
			if ($(this).scrollTop() > offset) {
				progressWrap.addClass('active-progress');
			} else {
				progressWrap.removeClass('active-progress');
			}
		});
		progressWrap.on('click', function(event) {
			event.preventDefault();
			$('html, body').animate(
				{
					scrollTop: 0,
				},
				duration
			);
			return false;
		});
	};
	return {
		init,
		backTop,
	};
})(window, jQuery);

export default onScroll;
