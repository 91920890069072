// export default function() {
// 	document.querySelectorAll('a[href*="#"]').forEach((anchor) => {
// 		anchor.addEventListener('click', function() {
// 			const hashId = this.getAttribute('href').match(/#[^?&\/]*/g);
//
// 			document.querySelector(hashId[0]).scrollIntoView({
// 				// behavior: 'smooth',
// 				behavior: 'smooth',
// 				block: 'start',
// 				inline: 'nearest',
// 			});
// 		});
// 	});
// }

const linkScroll = (($) => {
	const init = () => {
		$('a[href^="#"]').on('click', function(event) {
			const id = $(this).attr('href'),
				offset = $('.site-header').outerHeight() + 130,
				target = $(id).offset().top - offset;

			$('html, body').animate({ scrollTop: target }, 500);
			event.preventDefault();
		});
	};
	return {
		init,
	};
})(jQuery);

export default linkScroll;
